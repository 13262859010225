import api from '@/plugins/api'

const patients = {
  load: async function () {
    try {
      const response = await api.get('/patient')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  matchPrn: async function (prn) {
    try {
      const response = await api.get(`/patient/prn/${prn}`);
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  }
}

export default patients