var payments = {
    methods: {
        translateStatus: function (payment) {
            switch (payment.status) {
                case 'paid':
                    return 'success'
                case 'cancelled':
                case 'failed':
                    return 'danger'
                case 'pending':
                case 'confirmed':
                case 'processing':
                case 'partialy-paid':
                default:
                    return 'warning'
            }
        },
        isCancellable: function (payment) {
            switch (payment.status) {
                case 'pending':
                case 'confirmed':
                case 'processing':
                case 'partialy-paid':
                    return true
            }
            return false
        },
        isEditable: function (payment) {
            switch (payment.status) {
                case 'pending':
                case 'confirmed':
                case 'processing':
                case 'partialy-paid':
                    return true
            }
            return false
        },
        formatCardNumber: function (cardNumber) {
            return cardNumber.replace(/\s/g, "").replace(/(.{4})/g, "$1 ").trim();
        }
    }
}
export default payments;