import api from '@/plugins/api'
import fileDownloader from '@/plugins/file-downloader'

export const payments = {
  load: async function (filters) {
    try {
      const response = await api.get('/payments', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  schedules: async function (filters) {
    try {
      const response = await api.get('/payments/schedules', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  scheduleCounters: async function (filters) {
    try {
      const response = await api.get('/payments/schedules/counters', {
        params: filters
      })
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  scheduleEvents: async function (id) {
    try {
      const response = await api.get(`/payments/schedules/${id}/events`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  details: async function (id) {
    try {
      const response = await api.get(`/payments/${id}`)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function (data) {
    try {
      const response = await api.post('/payments', data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  update: async function (id, data) {
    try {
      const response = await api.put(`/payments/${id}`, data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  uploadDocument: async function (paymentId, files) {
    try {
      var formData = new FormData();
      for (const i in files) {
        formData.append(`file[${i}]`, files[i]);
      }
      formData.append('_method', 'PUT');
      const response = await api.post(
        `/payments/${paymentId}/documents`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      );
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  downloadDocument: async function (id, name) {
    try {
      const response = await api.post(`/payments/${id}/documents`, {}, {
        headers: {
          Accept: 'application/pdf',
        },
        responseType: 'blob',
      });
      fileDownloader.downloadBlobData(name, response.data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  downloadDocumentById: async function (id, doc) {
    try {
      const response = await api.get(`/payments/${id}/documents`, {
        headers: {
          Accept: 'application/pdf',
        },
        params: {
          filename: doc.filename,
        },
        responseType: 'blob',
      });
      fileDownloader.downloadBlobData(doc.original_filename, response.data)
      return response.data
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

export const paymentMethods = {
  load: async function () {
    try {
      const response = await api.get('/payment-methods')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  available: async function () {
    try {
      const response = await api.get('/payment-methods/available')
      return response.data
    } catch (e) {
      console.error(e)
    }
    return []
  },
  isCreditCard: function (slug) {
    return slug == 'credit-card';
  },
  isInherited: function (id) {
    return /^payment-/.test(id);
  },
  cardType: function (number) {
    var re = new RegExp("^4");
    if (number.match(re) != null)
      return "visa";

    if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
      return "mastercard";

    re = new RegExp("^3[47]");
    if (number.match(re) != null)
      return "amex";

    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
      return "discover";

    re = new RegExp("^36");
    if (number.match(re) != null)
      return "diners";

    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
      return "diners-carte-blanche";

    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
      return "jcb";

    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
      return "visa-electron";

    return "";
  }
}

export default payments