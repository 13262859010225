<template>
  <v-container v-if="request" fluid class="py-0">
    <v-row justify="center">
      <div class="payment-form">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <v-progress-linear
              color="#1D3557"
              height="6px"
              :value="25 * step"
            ></v-progress-linear>
            <h4 class="mt-2">
              {{
                `${$t(
                  `payments-.${currentPayment.id != "" ? "edit" : "new"}`
                )} (${step}/4)`
              }}
            </h4>
          </v-col>
        </v-row>
        <div :class="`payment-step ${step == 1 ? 'payment-step--active' : ''}`">
          <validation-observer v-slot="{ valid }">
            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mt-7">{{ $t("personal-information") }}</h4>
                <p class="mb-5">{{ $t("personal-information-text") }}</p>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-menu offset-y v-model="prnMenu" content-class="v-menu-input-text">
                  <template v-slot:activator="{}">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('prn').toLowerCase()"
                      :rules="{ required: true }"
                    >
                      <v-text-field
                        v-model="request.patient.prn"
                        outlined
                        :label="`${$t('prn')} (${$t('patient-registration-number')})`"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="item in prnPatients"
                      :key="item.id"
                      @click.prevent="usePatient(item)"
                    >
                      <v-list-item-title>
                        {{ `${item.prn} ${item.name} ${item.last_name}` }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="6" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('name').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.patient.name"
                    outlined
                    :label="$t('name')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('last-name').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.patient.last_name"
                    outlined
                    :label="$t('last-name')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" class="py-0">
                <h4 class="mt-2 mb-4">{{ $t("contact") }}</h4>
              </v-col>
              <v-col cols="12" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('phone-number').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-text-field
                    v-model="request.patient.phone_number"
                    outlined
                    :label="$t('phone-number')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('email').toLowerCase()"
                  :rules="{ required: true, email: true }"
                >
                  <v-text-field
                    v-model="request.patient.email"
                    outlined
                    :label="$t('email')"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" class="py-0">
                <h4 class="mt-2 mb-4">{{ $t("place-of-residence") }}</h4>
              </v-col>
              <v-col cols="12">
                <payment-address
                  @changed="syncPatientAddress($event)"
                  :baseAddress="patientBaseAddress"
                />
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="6" class="mb-10">
                <v-btn
                  color="primary"
                  :disabled="!valid || request.patient.address.formatted_address == ''"
                  elevation="0"
                  block
                  @click.prevent="step += 1"
                >
                  {{ $t("next") }}
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </div>
        <div :class="`payment-step ${step == 2 ? 'payment-step--active' : ''}`">
          <validation-observer v-slot="{ valid }">
            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mt-7">{{ $t("payment-plan") }}</h4>
                <p class="mb-5">{{ $t("payment-plan-text") }}</p>
              </v-col>
            </v-row>
            <v-radio-group v-model="request.type">
              <v-row>
                <v-col
                  cols="12"
                  :class="`payment-step-type ${
                    request.type == 'unique' ? 'payment-step-type--active' : ''
                  }`"
                  @click.prevent="request.type = 'unique'"
                >
                  <v-radio value="unique" />
                  <h4>{{ $t("payment-unique") }}</h4>
                  <p class="mb-0">{{ $t("payment-unique-text") }}</p>
                </v-col>
                <v-col
                  cols="12"
                  :class="`payment-step-type ${
                    isTypeMultiple ? 'payment-step-type--active' : ''
                  }`"
                  @click.prevent="request.type = 'multiple'"
                >
                  <v-radio value="multiple" />
                  <h4>{{ $t("payment-multiple") }}</h4>
                  <p class="mb-0">{{ $t("payment-multiple-text") }}</p>
                </v-col>
              </v-row>
            </v-radio-group>
            <v-row>
              <v-col
                cols="12"
                :class="`payment-step-type payment-step-type--month ${
                  isTypeMultiple ? 'payment-step-type--active' : 'd-none'
                }`"
              >
                <v-row>
                  <v-col>
                    <p class="mb-0">* {{ $t("payment-multiple-heading") }}</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-label>{{ $t("how-much-is-debt") }}</v-label>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <validation-provider
                      vid="amount"
                      v-slot="{ errors }"
                      :name="$t('amount').toLowerCase()"
                      :rules="{ required: isTypeMultiple, numeric: true }"
                    >
                      <v-text-field
                        v-model="paymentConfig.amount"
                        outlined
                        placeholder="0.00"
                        suffix="USD"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-label>{{
                      $t("how-often-do-you-want-to-make-the-payment")
                    }}</v-label>
                  </v-col>
                </v-row>
                <v-radio-group v-model="paymentConfig.periodicity">
                  <v-row>
                    <v-col cols="6">
                      <v-radio outlined class="v-radio--default" value="weekly">
                        <template v-slot:label>
                          <span>{{ $t("weekly") }}</span>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col cols="6">
                      <v-radio outlined class="v-radio--default" value="monthly">
                        <template v-slot:label>
                          <span>{{ $t("monthly") }}</span>
                        </template>
                      </v-radio>
                    </v-col>
                  </v-row>
                </v-radio-group>
                <v-divider></v-divider>
                <v-row v-if="paymentConfig.periodicity == 'weekly'" class="mt-6 mb-3">
                  <v-col cols="12" class="py-0">
                    <v-label>{{ $t("what-day-of-the-week") }}</v-label>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-chip-group v-model="paymentConfig.weekly_day" mandatory column>
                      <v-chip
                        v-for="(tag, i) in [
                          'sunday',
                          'monday',
                          'tuesday',
                          'wednesday',
                          'thursday',
                          'friday',
                          'saturday',
                        ]"
                        filter
                        active
                        :key="i"
                      >
                        {{ $t(tag).substr(0, 1) }}
                      </v-chip>
                    </v-chip-group>
                  </v-col>
                </v-row>
                <v-row v-else class="mt-6 mb-0">
                  <v-col cols="12" class="mb-3 py-0">
                    <v-label>{{ $t("what-day-of-the-month") }}</v-label>
                  </v-col>
                  <v-col cols="6" class="py-0 my-0">
                    <validation-provider
                      v-slot="{ errors }"
                      vid="day-of-month"
                      :name="$t('day-of-the-month').toLowerCase()"
                      :rules="{
                        required:
                          isTypeMultiple && paymentConfig.periodicity == 'monthly',
                        numeric: true,
                        regex: /^([1-9]|[1-2][0-9]|3[0-1])$/,
                      }"
                    >
                      <v-text-field
                        v-model="paymentConfig.monthly_day"
                        outlined
                        placeholder="31"
                        :error-messages="errors"
                      >
                        <template #prepend-inner>
                          <v-img
                            max-width="20px"
                            :src="require('@/assets/calendar.svg')"
                          />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-radio-group v-model="paymentConfig.segment">
                  <v-row class="mt-1">
                    <v-col cols="12" class="py-0">
                      <v-label>{{ $t("segment-it-for-the-next") }}:</v-label>
                    </v-col>
                    <v-col
                      v-for="segment in [2, 3, 4, 5, 6]"
                      :key="segment"
                      cols="4"
                      class="pb-0"
                    >
                      <v-radio outlined class="v-radio--default" :value="segment">
                        <template v-slot:label>
                          <span>{{
                            `${segment} ${$t(
                              isTypeMultiple && paymentConfig.periodicity == "weekly"
                                ? "weeks"
                                : "months"
                            ).toLowerCase()}`
                          }}</span>
                        </template>
                      </v-radio>
                    </v-col>
                    <v-col cols="4" class="pb-0 v-radio--with-input pt-0">
                      <v-radio outlined class="v-radio--default" :value="-1">
                        <template v-slot:label>
                          <span>{{ $t("more").toLowerCase() }}</span>
                        </template>
                      </v-radio>
                      <validation-provider
                        v-slot="{ errors }"
                        dense
                        vid="periodicity-value"
                        :name="
                          $t(
                            paymentConfig.periodicity == 'weekly' ? 'weeks' : 'months'
                          ).toLowerCase()
                        "
                        :rules="{ numeric: true }"
                      >
                        <v-text-field
                          v-model="paymentConfig.segmentMore"
                          :disabled="paymentConfig.segment != -1"
                          :error-messages="errors"
                        />
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="6" class="mb-10">
                <v-btn elevation="0" block @click.prevent="step -= 1">
                  {{ $t("previous") }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="mb-10">
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!valid || request.payments.length == 0"
                  elevation="0"
                  block
                  @click.prevent="step += 1"
                >
                  {{ $t("next") }}
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </div>
        <div :class="`payment-step ${step == 3 ? 'payment-step--active' : ''}`">
          <validation-observer v-slot="{ valid }">
            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mt-7">{{ $t("payment-configuration") }}</h4>
                <p class="mb-5">
                  {{ $t(`payment-configuration-${request.type}-text`) }}
                </p>
              </v-col>
            </v-row>
            <v-row v-for="(payment, i) of request.payments" :key="i">
              <v-col v-if="request.payments.length > 1" cols="12">
                <h2>{{ `${$t("payment")} #${i + 1}` }}</h2>
              </v-col>
              <v-col cols="6" class="pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  :vid="`payment-${i}-amount`"
                  :name="$t('amount').toLowerCase()"
                  :rules="{ required: true, min_value: 1, numeric: true }"
                >
                  <v-text-field
                    v-model="payment.amount"
                    outlined
                    placeholder="0.00"
                    suffix="USD"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="6" class="pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  :vid="`payment-${i}-scheduled-at`"
                  :name="$t('scheduled-at').toLowerCase()"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-menu transition="scale-transition" offset-y min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="payment.scheduled_at"
                        :label="$t('scheduled-at')"
                        readonly
                        outlined
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template #prepend-inner>
                          <v-img
                            max-width="20px"
                            :src="require('@/assets/calendar.svg')"
                          />
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="payment.scheduled_at"
                      :min="
                        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                          .toISOString()
                          .substr(0, 10)
                      "
                    >
                    </v-date-picker>
                  </v-menu>
                </validation-provider>
              </v-col>
              <v-col cols="12" class="pb-0">
                <validation-provider
                  v-slot="{ errors }"
                  :vid="`payment-${i}-method`"
                  :name="$t('payment-method').toLowerCase()"
                  :rules="{
                    required: true,
                  }"
                >
                  <v-select
                    :items="payment.methods"
                    v-model="payment.method"
                    outlined
                    item-value="id"
                    item-text="id"
                    return-object
                    :error-messages="errors"
                    @change="changedPaymentMethod(i, payment)"
                  >
                    <template #selection="{ item }">
                      {{
                        `${
                          /^payment\-[1-9]+$/.test(item.id)
                            ? `${$t("same-as-payment")} #${item.id.replace(
                                "payment-",
                                ""
                              )}`
                            : $t(item.slug)
                        }`
                      }}
                    </template>
                    <template #item="{ item }">
                      {{
                        `${
                          /^payment\-[1-9]+$/.test(item.id)
                            ? `${$t("same-as-payment")} #${item.id.replace(
                                "payment-",
                                ""
                              )}`
                            : $t(item.slug)
                        }`
                      }}
                    </template>
                  </v-select>
                </validation-provider>
              </v-col>
              <v-col v-if="payment.method.slug == 'credit-card'" cols="12">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <h4 class="mt-0 mb-2">{{ $t("credit-card-details") }}</h4>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      :label="$t('use-the-same-first-and-last-name-as-the-patient')"
                      class="mt-0"
                      v-model="payment.use_patient_name"
                      @change="usePatientName(payment)"
                    />
                  </v-col>
                  <v-col v-if="!payment.use_patient_name" cols="6" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      :vid="`payment-${i}-name`"
                      :name="$t('name').toLowerCase()"
                      :rules="{
                        required: true,
                      }"
                    >
                      <v-text-field
                        v-model="payment.cardholder_name"
                        outlined
                        :label="$t('name')"
                        :error-messages="errors"
                        :disabled="payment.use_patient_name"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col v-if="!payment.use_patient_name" cols="6" class="py-0">
                    <validation-provider
                      v-slot="{ errors }"
                      :vid="`payment-${i}-last-name`"
                      :name="$t('last-name').toLowerCase()"
                      :rules="{
                        required: true,
                      }"
                    >
                      <v-text-field
                        v-model="payment.cardholder_last_name"
                        outlined
                        :label="$t('last-name')"
                        :error-messages="errors"
                        :disabled="payment.use_patient_name"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="6" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      :vid="`payment-${i}-card-number`"
                      :name="$t('credit-card-number').toLowerCase()"
                      :rules="{
                        required: true,
                        card_number: true,
                      }"
                    >
                      <v-text-field
                        v-model="payment.card_number"
                        outlined
                        :label="$t('credit-card-number')"
                        :error-messages="errors"
                        persistent-hint
                        @keyup="maskCardNumber(payment)"
                      >
                        <template #append>
                          <v-img
                            v-if="cardType(payment.card_number) != ''"
                            max-width="40px"
                            :src="
                              require(`@/assets/cards/${cardType(
                                payment.card_number
                              )}.png`)
                            "
                          />
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="3" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      :vid="`payment-${i}-cvc`"
                      :name="$t('cvc').toLowerCase()"
                      :rules="{
                        required: true,
                        numeric: true,
                        regex: /^[0-9]{3,4}$/,
                      }"
                    >
                      <v-text-field
                        v-model="payment.cvc"
                        type="password"
                        outlined
                        :label="$t('cvc')"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col cols="3" class="pb-0">
                    <validation-provider
                      v-slot="{ errors }"
                      :vid="`payment-${i}-exp`"
                      :name="$t('expiration').toLowerCase()"
                      :rules="{
                        required: true,
                        regex: /^(0?[1-9]{1}|1[1-2]{1})\/[1-9]{2}$/,
                      }"
                    >
                      <v-text-field
                        v-model="payment.exp"
                        outlined
                        :hint="$t('expiration-hint')"
                        persistent-hint
                        :label="$t('expiration')"
                        :error-messages="errors"
                        @keyup="maskExpiration($event, payment)"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col v-if="payment.method.slug == 'credit-card'" cols="12">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <h4 class="mt-0">{{ $t("invoicing-address") }}</h4>
                  </v-col>
                  <v-col cols="12" class="pb-0">
                    <v-checkbox
                      v-model="payment.use_patient_address"
                      :label="$t('use-the-same-address-as-the-patient')"
                      class="mt-0"
                      @change="usePatientAddress(payment)"
                    ></v-checkbox>
                  </v-col>
                  <v-col v-if="!payment.use_patient_address" cols="12">
                    <payment-address
                      @changed="syncPaymentAddress(i, $event)"
                      :baseAddress="payment.address"
                      :iterationKey="`${i}`"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="6" class="mb-10">
                <v-btn elevation="0" block @click="step -= 1">
                  {{ $t("previous") }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="mb-10">
                <v-btn
                  color="primary"
                  :disabled="!valid"
                  elevation="0"
                  block
                  @click.prevent="handleSubmit"
                >
                  {{ $t("next") }}
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </div>
        <div :class="`payment-step ${step == 4 ? 'payment-step--active' : ''}`">
          <validation-observer v-slot="{ valid }">
            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mt-7">{{ $t("payment-documents") }}</h4>
                <p class="mb-5">
                  {{ $t(`payment-documents-text`) }}
                </p>
              </v-col>
              <v-col cols="12" class="py-0 mb-5">
                <v-btn depressed plain color="primary" @click.prevent="downloadFile">
                  <v-img
                    max-width="24px"
                    :src="require('@/assets/download.svg')"
                    class="mr-2"
                  />
                  {{ $t("download") }}
                </v-btn>
              </v-col>
              <v-col v-for="(item, i) in uploadFiles" :key="i" cols="12" class="py-0">
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('attach-file').toLowerCase()"
                  :rules="{ required: true }"
                >
                  <v-file-input
                    v-model="uploadFiles[i]"
                    outlined
                    persistent-hint
                    :hint="$t('max-file-upload')"
                    prepend-icon=""
                    show-size
                    :label="$t('attach-file')"
                    :error-messages="errors"
                  >
                    <template #prepend-inner>
                      <v-img
                        max-width="20px"
                        :src="require('@/assets/file-upload.svg')"
                      />
                    </template>
                  </v-file-input>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="6" class="mb-10">
                <v-btn elevation="0" block @click="step -= 1">
                  {{ $t("previous") }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="mb-10">
                <v-btn
                  color="primary"
                  type="submit"
                  :disabled="!valid"
                  elevation="0"
                  block
                  @click.prevent="uploadFile"
                >
                  {{ $t("upload-file") }}
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { payments, paymentMethods } from "../../requests/payments";
import patients from "../../requests/patients";
import paymentsMixin from "../../mixins/payments";
import PaymentAddress from "../components/Address";
import * as dayjs from "dayjs";

export default {
  mixins: [paymentsMixin],
  components: {
    PaymentAddress,
  },
  data: () => ({
    step: 1,
    date: "",
    paymentMethods: [],
    countries: [],
    prnPatients: [],
    prnMenu: false,
    prnTimeout: null,
    calculateTimeout: null,
    uploadFiles: [null],
    request: null,
    patientBaseAddress: null,
    emptyRequest: {
      amount: "",
      type: "unique",
      patient: {
        prn: "",
        name: "",
        last_name: "",
        phone_number: "",
        email: "",
        address: "",
        apto: "",
        country: "",
        state: "",
        city: "",
        zip_code: "",
        formatted_address: "",
      },
      payments: [],
    },
    paymentConfig: {
      periodicity: "weekly",
      weekly_day: 0,
      monthly_day: 1,
      segment: 2,
      amount: "",
      segmentMore: "",
    },
    payment: {
      method: {},
      amount: "",
      card_number: "",
      cvc: "",
      exp: "",
      use_patient_name: false,
      cardholder_name: "",
      cardholder_last_name: "",
      scheduled_at: "",
      use_patient_address: false,
      address: {
        address: "",
        apto: "",
        country: "",
        country_id: "",
        state: "",
        state_id: "",
        city: "",
        zip_code: "",
      },
    },
    currentPayment: {
      id: "",
    },
  }),
  computed: {
    isTypeMultiple: function () {
      return this.request.type === "multiple";
    },
    isPeriodicityWeekly: function () {
      return this.paymentConfig.periodicity === "weekly";
    },
  },
  watch: {
    "request.type": async function () {
      this.request.payments = [];
      if (!this.isTypeMultiple) {
        this.addSinglePayment();
      }
    },
    "request.patient.prn": async function (value) {
      this.prnPatients = [];
      this.prnMenu = false;
      if (this.prnTimeout) {
        clearTimeout(this.prnTimeout);
      }
      this.prnTimeout = setTimeout(async () => {
        if (value > 3) {
          this.prnPatients = await patients.matchPrn(value);
          this.prnMenu = this.prnPatients.length > 0;
        }
      }, 1000);
    },
    paymentConfig: {
      handler: async function () {
        this.request.payments = [];
        this.methods = [];
        if (this.calculateTimeout) {
          clearTimeout(this.calculateTimeout);
        }
        this.calculateTimeout = setTimeout(() => {
          this.request.amount = this.paymentConfig.amount;
          this.paymentConfig.segmentMore =
            this.paymentConfig.segment == -1 ? this.paymentConfig.segmentMore : "";

          const segments =
            this.paymentConfig.segment == -1
              ? this.paymentConfig.segmentMore
              : this.paymentConfig.segment;
          if (this.request.amount > 0 && segments > 0) {
            let segmentedAmount = Math.round(this.request.amount / segments);
            let nextDate = dayjs();
            for (let i = 0; i < segments; i++) {
              segmentedAmount =
                i + 1 == segments
                  ? this.paymentConfig.amount - segmentedAmount * (segments - 1)
                  : segmentedAmount;
              nextDate = nextDate.add(1, this.isPeriodicityWeekly ? "week" : "month");
              if (this.isPeriodicityWeekly) {
                nextDate = nextDate.day(this.paymentConfig.weekly_day);
              } else {
                nextDate = nextDate.date(this.paymentConfig.monthly_day);
              }
              this.addPayment(segmentedAmount, nextDate.format("YYYY-MM-DD"));
            }
          }
        }, 1000);
      },
      deep: true,
    },
  },
  created: async function () {
    this.paymentMethods = await paymentMethods.available();
    this.request = Object.assign({}, this.emptyRequest);
    if (this.$route.params.id) {
      try {
        const payment = await payments.details(this.$route.params.id);
        this.currentPayment.id = payment.id;
        this.paymentConfig.amount = payment.amount;
        this.paymentConfig.segment = payment.schedules.length;
        if (payment.schedules.length > 6) {
          this.paymentConfig.segment = -1;
          this.paymentConfig.segmentMore = payment.schedules.length;
        }
        this.request.type = payment.type == "unique" ? "unique" : "multiple";
        this.request.patient = {
          prn: payment.patient.prn,
          name: payment.patient.name,
          last_name: payment.patient.last_name,
          phone_number: payment.patient.phone_number,
          email: payment.patient.email,
        };
        if (payment.schedules.length > 1) {
          const first = dayjs(payment.schedules[0].scheduled_at);
          const second = dayjs(payment.schedules[1].scheduled_at);

          this.paymentConfig.weekly_day = first.format("d");
          this.paymentConfig.monthly_day = first.format("D");
          if (Math.abs(first.diff(second, "day")) > 20) {
            this.paymentConfig.periodicity = "weekly";
          }
        }
        this.request.payments = [];

        const entries = [];
        for (const item of payment.schedules) {
          const entry = this.buildPayment(
            item.amount,
            dayjs(item.scheduled_at).format("YYYY-MM-DD"),
            entries
          );
          for (const method of entry.methods) {
            if (method.id == item.method) {
              entry.method = method;
              break;
            }
          }
          entry.id = item.id;
          entry.card_number = item.card_number;
          entry.cvc = item.card_cvc;
          entry.exp = item.card_expiration;
          entry.use_patient_name = item.use_patient_name == 1;
          entry.cardholder_name = item.cardholder_name;
          entry.cardholder_last_name = item.cardholder_last_name;
          entry.use_patient_address = item.use_patient_address == 1;
          entry.address.address = item.address;
          entry.address.apto = item.apartment;
          entry.address.country_id = item.country_id;
          entry.address.state_id = item.state_id;
          entry.address.city = item.city;
          entry.address.zip_code = item.zip_code;
          entry.address.formatted_address = item.formatted_address;
          this.maskCardNumber(entry);
          entries.push(entry);
        }
        setTimeout(
          function () {
            this.usePatient(payment.patient);
          }.bind(this),
          1000
        );
        setTimeout(
          function () {
            this.request.payments = entries;
          }.bind(this),
          2000
        );
      } catch (e) {
        console.error(e);
        this.$router.push({ name: "payments" });
      }
    }
    this.$store.dispatch("DISABLE_LOADING");
  },
  methods: {
    addPayment: function (amount, date) {
      this.request.payments.push(this.buildPayment(amount, date, this.request.payments));
    },
    buildPayment: function (amount, date, prevItems) {
      const payment = Object.assign({}, this.payment);
      const tempMethods = [];
      for (const method of this.paymentMethods) {
        tempMethods.push({
          id: method.id,
          slug: method.slug,
        });
      }
      for (let i = 0; i < prevItems.length; i++) {
        tempMethods.push({
          id: `payment${i * -1 - 1}`,
          slug: "same-as",
        });
      }
      payment.methods = tempMethods;
      payment.method = payment.methods[0];
      payment.amount = amount;
      payment.scheduled_at = date;
      return payment;
    },
    usePatient: function (patient) {
      this.request.patient = patient;

      const address = {};
      address.address = patient.address;
      address.apto = patient.apto;
      address.country_id = patient.country_id;
      address.state_id = patient.state_id;
      address.city = patient.city;
      address.zip_code = patient.zip_code;

      this.patientBaseAddress = address;
    },
    addSinglePayment: function () {
      this.addPayment(0, dayjs().format("YYYY-MM-DD"));
    },
    syncPatientAddress: async function (address) {
      if (!address) {
        return;
      }
      this.request.patient.address = address.address;
      this.request.patient.apto = address.apto;
      this.request.patient.country = address.country ? address.country.id : "";
      this.request.patient.state = address.state ? address.state.id : "";
      this.request.patient.city = address.city;
      this.request.patient.zip_code = address.zip_code;
      this.request.patient.formatted_address = address.formatted_address;
    },
    syncPaymentAddress: async function (i, address) {
      if (!address) {
        return;
      }

      const newAddress = {};
      newAddress.address = address.address;
      newAddress.apto = address.apto;
      newAddress.country = address.country ? address.country.id : "";
      newAddress.country_id = newAddress.country;
      newAddress.state = address.state ? address.state.id : "";
      newAddress.state_id = newAddress.state;
      newAddress.city = address.city;
      newAddress.zip_code = address.zip_code;
      newAddress.formatted_address = address.formatted_address;
      this.request.payments[i].address = newAddress;
    },
    handleSubmit: async function () {
      try {
        let amount = this.isTypeMultiple
          ? this.request.amount
          : this.request.payments[0].amount;
        const entries = [];
        for (const payment of this.request.payments) {
          let entry = Object.assign({}, payment);

          const prevPayment = /^payment-/.test(payment.method.id);
          if (prevPayment) {
            entry = {
              amount: payment.amount,
              scheduled_at: payment.scheduled_at,
              method: payment.method.id,
            };
          } else {
            entry.method = payment.method.slug;
            if (entry.method == "credit-card") {
              entry.card_number = payment.card_number.replace(/\s/g, "");
            }
            if (payment.use_patient_name) {
              entry.cardholder_name = this.request.patient.name;
              entry.cardholder_last_name = this.request.patient.last_name;
            }
            if (payment.use_patient_address) {
              entry.address = this.request.patient.address;
              entry.apto = this.request.patient.apto;
              entry.country = this.request.patient.country;
              entry.state = this.request.patient.state;
              entry.city = this.request.patient.city;
              entry.zip_code = this.request.patient.zip_code;
            }
          }

          entry.id = payment.id;
          entries.push(entry);
        }
        const data = {
          amount: amount,
          type: this.isTypeMultiple ? "scheduled" : this.request.type,
          patient: this.request.patient,
          payments: entries,
        };
        if (this.currentPayment.id == "") {
          this.currentPayment = await payments.create(data);
          for (const i in this.currentPayment.schedules) {
            this.request.payments[i].id = this.currentPayment.schedules[i].id;
          }
        } else {
          await payments.update(this.currentPayment.id, data);
        }

        this.downloadFile();
        this.step += 1;
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    changedPaymentMethod: async function (index, payment) {
      if (index == 0) {
        return;
      }
      const isInherited = paymentMethods.isInherited(payment.method.id);
      for (let j = index + 1; j < this.request.payments.length; j++) {
        const entry = this.request.payments[j];
        const position = entry.methods.findIndex((x) => x.id == `payment-${index + 1}`);
        if (position > 0 && isInherited) {
          entry.methods.splice(position, 1);
        } else if (position < 0 && !isInherited) {
          entry.methods.splice(this.paymentMethods.length + index, 0, {
            id: `payment${(index + 1) * -1}`,
            slug: "same-as",
          });
        }
      }
    },
    usePatientName: async function (payment) {
      payment.cardholder_name = this.request.patient.name;
      payment.cardholder_last_name = this.request.patient.last_name;
    },
    usePatientAddress: async function (payment) {
      const newAddress = {};
      newAddress.address = this.request.patient.address;
      newAddress.apto = this.request.patient.apto;
      newAddress.country = this.request.patient.country;
      newAddress.state = this.request.patient.state;
      newAddress.city = this.request.patient.city;
      newAddress.zip_code = this.request.patient.zip_code;
      payment.address = newAddress;
    },
    cardType: function (number) {
      return paymentMethods.cardType(number.replace(/\s/g, ""));
    },
    maskCardNumber: function (payment) {
      payment.card_number = this.formatCardNumber(payment.card_number);
    },
    maskExpiration: function ($evt, payment) {
      const parts = payment.exp.split("/", 2);
      if (parts.length == 2 && parts[0].length == 1) {
        payment.exp = `0${payment.exp}`;
      }
      payment.exp = payment.exp.replace(/\//g, "").replace(/(.{2})/g, "$1/");
      if (payment.exp.length > 4 || $evt.keyCode == 8) {
        payment.exp = payment.exp.replace(/\/$/, "");
      }
    },
    downloadFile: async function () {
      try {
        await payments.downloadDocument(
          this.currentPayment.id,
          `Payment ${this.request.patient.name} ${
            this.request.patient.last_name
          } (${dayjs().format("YYYY-MM-DD")})`
        );
      } catch (e) {
        console.error(e);
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
    uploadFile: async function () {
      try {
        await payments.uploadDocument(this.currentPayment.id, this.uploadFiles);
        this.request = Object.assign({}, this.emptyRequest);
        this.$router.push({
          name: "payments-details",
          params: { id: this.currentPayment.id },
        });
      } catch (e) {
        await this.$store.dispatch("notificator/errorResponse", e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .row {
    h4 {
      font-size: 1.2rem !important;
      color: #1d3557;
    }
  }

  .payment-form {
    width: 100%;
    max-width: 520px;
    padding-bottom: 20px;
    .payment-step {
      display: none;
      &.payment-step--active {
        display: block;
      }
      .payment-step-type {
        padding: 32px 32px 32px 80px;
        border-radius: 24px;
        transition: all 1s ease;
        position: relative;
        cursor: pointer;
        &.payment-step-type--month {
          padding: 32px;
          margin-top: 5px;
          p {
            color: #7cc7ca;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
          label {
            font-weight: 500;
            font-size: 15px;
            line-height: 120%;
            color: #1d3557;
          }
        }
        &.payment-step-type--active,
        &:hover {
          background-color: white;
          box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.04);
        }
        + .payment-step-type {
          margin-top: 20px;
        }
        .v-radio--with-input {
          .v-radio,
          span {
            display: inline-block;
            width: 50%;
          }
          span .v-input {
            margin: 0;
          }
        }
        .v-radio.v-radio--default {
          span {
            color: #333333;
            font-weight: 400;
            font-size: 16px;
          }
        }
        .v-radio:not(.v-radio--default) {
          position: absolute;
          left: 35px;
          top: 45px;
        }
      }
    }
  }
}
</style>
